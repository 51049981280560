import { Link } from 'gatsby';
import {
  arrayOf, bool, func, number, string, shape,
} from 'prop-types';
import { Formik } from 'formik';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Content } from '../../common/content';
import { Input } from '../../common/form';
import { PriceFormat } from '../../common/price';
import { useCart, MAX_UNITS } from '../../../hooks/useCart';
import { useNotification } from '../../../hooks/useNotification';
import { useShop, CURRENCY } from '../../../hooks/useShop';
import './cart.scss';

const bem = new BEMHelper({
  name: 'content-shop-cart',
});

const useSchema = (moq, moqIncrement) => {
  const { t } = useTranslation();

  return useMemo(
    () => Yup.object().shape({
      quantity: Yup
        .number()
        .min( (moq ? moq : 1), t('Please choose a correct quantity.'))
        .max(MAX_UNITS, t('Please choose a smaller quantity.'))
        .required(t('Please choose a quantity.')),
    }),
    [],
  );
};

const Heading = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr {...bem('listing__heading')}>
        <td {...bem('listing__heading__name')}>{t('Product')}</td>
        <td {...bem('listing__heading__quantity')}>{t('Units')}</td>
        <td {...bem('listing__heading__price')} colSpan="2">{t('Price')}</td>
      </tr>
    </thead>
  );
};

const ProductRow = ({
  id, pId, vId,
  title, label, url, moq, moqIncrement, articleNo,
  quantity: q, price, sample,
  onUpdate,
  onRemove,
}) => {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(q);
  const { addNotification } = useNotification();
  const schema = useSchema(moq, moqIncrement);
  const handleRemove = useCallback(
    () => {
      onRemove(pId, vId, sample);
      addNotification('success', t('Product removed from cart.'));
    },
    [pId, vId, sample, onRemove, addNotification],
  );

  // MDS-3
  const minimumOrderQuantity = (typeof moq === 'number' && !sample) ? moq : 1;
  const quantityInputType = (typeof moqIncrement === 'number' && !sample) ? 'select' : 'input';

  const quantityOptions = {};

  // Fill quantity options only if an increment is set and no sample was requested
  if(moqIncrement && !sample){
    quantityOptions['children'] = new Array(10).fill('', 0, 10).map((p,i) =>
      <option key={`quantityOption_` + i} value={parseInt(moq) + parseInt(i * moqIncrement)}>{parseInt(moq) + parseInt(i * moqIncrement)}</option>
    )
  }

  return (
    <Formik initialValues={{ quantity: q }} validationSchema={schema}>
      {({ values, errors, handleBlur }) => {
        useEffect(
          () => {
            if (values.quantity === quantity) {
              // Prevent from updating quantity if not changed. Would fail when
              // try to change quantity of sample item on initial render.
              return;
            }

            if (values.quantity === 0) {
              // Prevent from updating quantity if quantity is 0. User should
              // use remove button. Otherwise entry will disappear more or less
              // silently when starting to type with a zero.
              return;
            }

            if (values.quantity > MAX_UNITS) {
              // Prevent from increasing max quantity.
              return;
            }

            if (values.quantity < moq) {
              // Prevent from decreasing min quantity.
              return;
            }

            setQuantity(values.quantity || 0);
            try {
              onUpdate(pId, vId, values.quantity || 1, sample);
            } catch (error) {
              addNotification('error', error.message);
            }
          },
          [pId, vId, values, sample, setQuantity, onUpdate],
        );

        return (
          <tr {...bem('listing__entry')}>
            <td {...bem('listing__entry__name')}>
              <Link to={url}>
                <span {...bem('listing__entry__name__title')}>{title}</span>
                {label && (<span {...bem('listing__entry__name__label')}>{label}</span>)}
                {moq ? 'Minimum order quantity: ' + moq : ''}
              </Link>
            </td>
            <td {...bem('listing__entry__quantity')}>
              <Input
                id={id}
                as={quantityInputType}
                {...quantityOptions}
                label={t('Set quantity for {{ title }}, {{ label }}', { title, label })}
                name="quantity"
                type="number"
                value={quantity || 0}
                error={errors.quantity}
                readonly={sample}
                inputMode="numeric"
                pattern="[1-9][0-9]+"
                min={moq ? moq : 1}
                max={MAX_UNITS}
                maxLength="4"
                step={moqIncrement ? moqIncrement : 1}
                onBlur={handleBlur}
              />
            </td>
            <td {...bem('listing__entry__price')}>
              {quantity === 0
                ? (<PriceFormat currency={CURRENCY} value={sample ? 0 : (1 * price)} />)
                : (<PriceFormat currency={CURRENCY} value={sample ? 0 : (quantity * price)} />)}
            </td>
            <td {...bem('listing__entry__actions')}>
              <button
                {...bem('listing__entry__actions__remove')}
                type="button"
                title={t('Remove from cart')}
                onClick={handleRemove}
              >
                <span {...bem('listing__entry__actions__remove__label')}>
                  {t('remove')}
                </span>
              </button>
            </td>
          </tr>
        );
      }}
    </Formik>
  );
};

ProductRow.propTypes = {
  id: string.isRequired,
  pId: string.isRequired,
  vId: string.isRequired,
  title: string.isRequired,
  label: string,
  url: string.isRequired,
  quantity: number.isRequired,
  price: number.isRequired,
  sample: bool.isRequired,
  onUpdate: func.isRequired,
  onRemove: func.isRequired,
};

ProductRow.defaultProps = {
  label: undefined,
};

const CouponRow = ({
  id, code, value, onRemove,
}) => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const handleRemove = useCallback(
    () => {
      onRemove(id);
      addNotification('success', t('Coupon code removed.'));
    },
    [code, onRemove, addNotification],
  );

  return (
    <tr {...bem('listing__entry')}>
      <td {...bem('listing__entry__name')} colSpan="2">
        <span {...bem('listing__entry__name__title')}>{`${t('Coupon code')}: `}</span>
        <span {...bem('listing__entry__name__label')}>{code}</span>
      </td>
      <td {...bem('listing__entry__price')}>
        {`-${value}%`}
      </td>
      <td {...bem('listing__entry__actions')}>
        <button
          {...bem('listing__entry__actions__remove')}
          type="button"
          title={t('Remove coupon')}
          onClick={handleRemove}
        >
          <span {...bem('listing__entry__actions__remove__label')}>
            {t('remove')}
          </span>
        </button>
      </td>
    </tr>
  );
};

CouponRow.propTypes = {
  id: string.isRequired,
  code: string.isRequired,
  value: number.isRequired,
  onRemove: func.isRequired,
};

export const Cart = ({ products, codes }) => {
  const { t } = useTranslation();
  const {
    cart, isEmpty,
    updateCart, removeFromCart,
  } = useCart();
  const {
    subtotal, total, coupons,
    hasDiscount, isEnabled, isDiscountEnabled,
    lookup, removeCoupon,
  } = useShop(products, codes);

  if (!isEnabled) {
    return null;
  }

  if (isEmpty) {
    return (
      <Content {...bem()}>
        <h1 {...bem('headline')}>{t('Your cart')}</h1>
        <div {...bem('cart')}>
          <p {...bem('description')}>
            {t('Your cart is empty. Please add some products to place your order.')}
          </p>
        </div>
      </Content>
    );
  }

  return (
    <Content {...bem()} useOwnSpacing>
      <h1 {...bem('headline')}>{t('Your cart')}</h1>
      <div {...bem('cart')}>
        <p {...bem('description')}>
          {t('After submitting an order you will receive an email from sales to confirm the order and proceed with payment.')}
        </p>
        <div {...bem('wrapper')}>
          <table {...bem('listing')}>
            <Heading />
            <tbody>
              {cart.map(({
                pId, vId, quantity, sample,
              }) => {
                const product = lookup(pId, vId);
                if (!product) {
                // Product not available: Maybe longer in cart and currently not
                // published by CMS...
                  return null;
                }

                const key = `${product.key}${sample ? '__sample' : ''}`;
                return (
                  <ProductRow
                    key={key}
                    id={key}
                    pId={pId}
                    vId={vId}
                    articleNo={ product.articleNo }
                    quantity={ parseInt(quantity) }
                    url={product.url}
                    moq={product.moq}
                    moqIncrement={product.moqIncrement}
                    price={product.price}
                    title={product.title}
                    label={product.standalone ? product.label : undefined}
                    sample={sample}
                    onUpdate={updateCart}
                    onRemove={removeFromCart}
                  />
                );
              })}
            </tbody>
            <tfoot {...bem('listing__footer')}>
              <tr {...bem('listing__footer__sum')}>
                <td {...bem('listing__footer__label')} colSpan="2">{isDiscountEnabled && hasDiscount ? t('Subtotal') : t('Total')}</td>
                <td {...bem('listing__footer__total')} colSpan="2">
                  <PriceFormat currency={CURRENCY} value={subtotal} />
                </td>
              </tr>
              {isDiscountEnabled && coupons.map(({ cId }) => {
                const coupon = codes.find((code) => code.uid === cId);
                if (!coupon) {
                  return null;
                }

                return (
                  <CouponRow
                    key={cId}
                    id={cId}
                    code={coupon.code}
                    value={coupon.value}
                    onRemove={removeCoupon}
                  />
                );
              })}
              {isDiscountEnabled && hasDiscount && (
                <tr {...bem('listing__footer__sum')}>
                  <td {...bem('listing__footer__label')} colSpan="2">{t('Total')}</td>
                  <td {...bem('listing__footer__total')} colSpan="2">
                    <PriceFormat currency={CURRENCY} value={total} />
                  </td>
                </tr>
              )}
            </tfoot>
          </table>
        </div>
      </div>
    </Content>
  );
};

Cart.propTypes = {
  products: arrayOf(shape({
    uid: string.isRequired,
    title: string.isRequired,
    slug: string.isRequired,
    departments: arrayOf(shape({
      slug: string.isRequired,
    })).isRequired,
    variants: arrayOf(shape({
      uid: string.isRequired,
      label: string.isRequired,
      slug: string.isRequired,
      price: number.isRequired,
      sample: bool.isRequired,
    })).isRequired,
  })).isRequired,
  codes: arrayOf(shape({
    uid: string.isRequired,
    code: string.isRequired,
    value: number.isRequired,
  })).isRequired,
};
