import { Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import {
  arrayOf, bool, number, string, shape,
} from 'prop-types';
import React, { useCallback } from 'react';
import BEMHelper from 'react-bem-helper';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '../../common/button';
import { Content } from '../../common/content';
import { resolve } from '../../../helpers/urls';
import { Comment } from '../comment';
import { useAuth } from '../../../hooks/useAuth';
import { useCart } from '../../../hooks/useCart';
import { useNotification } from '../../../hooks/useNotification';
import { postForm } from '../../../helpers/netlify';
import { useShop, CURRENCY } from '../../../hooks/useShop';
import './order.scss';

const NETLIFY_FORM_NAME = 'Website Order';

const bem = new BEMHelper({
  name: 'content-shop-order',
});

const now = new Date();
const orderReference =  now.getFullYear().toString()
  + (now.getMonth() + 1).toString().padStart(2, '0')
  + now.getDate().toString()
  + '-' + now.getHours().toString() + now.getMinutes();

const buildMessage = (products, codes) => {
  const { cart } = useCart();
  const {
    lookup, subtotal, total, coupons, hasDiscount, isDiscountEnabled, contactperson, comment
  } = useShop(products, codes);
  const spacer = '--------------------------------------\n';
  let message = '';

  message += 'Products:\n';
  message += spacer;

  cart.forEach(({
    pId, vId, quantity, sample,
  }) => {
    const product = lookup(pId, vId);
    if (!product) {
      // Product not available: Maybe longer in cart and currently not
      // published by CMS... Skip
      return;
    }

    message += `${product.title}\n`;
    if (product.articleNo !== null) {
      message += `    Article no.: ${product.articleNo}\n`;
    }
    message += `    Variant: ${product.label}\n`;
    message += `    Quantity: ${quantity}\n`;
    message += `    Price per unit: ${sample ? 'Free sample' : `${product.price}${CURRENCY}`}\n`;
    message += `    Page: ${process.env.GATSBY_SITE_ORIGIN}${product.url}\n`;
    message += '\n\n';
  });

  if (isDiscountEnabled && hasDiscount) {
    message += `Subtotal: ${subtotal}${CURRENCY}\n`;

    message += '\n\n';
    message += 'Discount codes:\n';
    message += spacer;

    coupons.forEach(({ cId }) => {
      const coupon = codes.find(({ uid }) => uid === cId);
      if (!coupon) {
        return;
      }

      message += `${coupon.code}\n`;
      message += `    Discount value: -${coupon.value}%\n`;
      message += '\n\n';
    });
  }

  message += `Total: ${total}${CURRENCY}\n`;

  if (isDiscountEnabled && hasDiscount) {
    message += '(The total price contains a discount. Please ensure the applied discount value is correct.)';
  }

  if (contactperson) {
    message += spacer;
    message += "Contact name: " + contactperson  + `\n`;
  }

  if (comment) {
    message += spacer;
    message += "Comment: " + comment  + `\n`;
  }

  message += spacer;
  message += "Order reference: " + orderReference  + `\n`;
  message += spacer + `\n`;

  return message;
};

export const Order = ({ products, codes }) => {
  const { t } = useTranslation();
  const { isAuthenticated, user } = useAuth();
  const { isEmpty, clearCart } = useCart();
  const { addNotification } = useNotification();
  const { clearCoupons, isEnabled } = useShop(products, codes);
  const order = buildMessage(products, codes);

  const onSubmit = useCallback((values, { setStatus, setSubmitting }) => {
    const submit = async () => {
      try {
        await postForm(NETLIFY_FORM_NAME, {
          email: user.email,
          subject: `New Order from SkinCare Customer ${orderReference}`,
          order,
        });
        setStatus({ success: true });
        clearCoupons();
        clearCart();
        addNotification('success', t('Successfully placed your order.'));
        const homepage = resolve('home');
        navigate(homepage);
      } catch (error) {
        console.log(error);
        addNotification('error', t('An error occurred'));
        setStatus({ success: false });
        setSubmitting(false);
      }
    };
    submit();
  }, [order, products, clearCart, addNotification]);

  if (!isEnabled) {
    return null;
  }

  if (isEmpty || !isAuthenticated || !user?.email) {
    // This is a helper form to help netlify to find and register the form and
    // fields during the build process. The form should be hidden to appear like:
    // `return null;`
    return (
      <form data-netlify="true" name={NETLIFY_FORM_NAME} style={{ display: 'none' }}>
        <input type="hidden" name="form-name" value={NETLIFY_FORM_NAME} />
        <input type="hidden" name="email" value="" />
        <input type="hidden" name="order" value="" />
        <input type="hidden" name="subject" value="New Order from SkinCare Customer" />
      </form>
    );
  }

  return (
    <Content {...bem()} useOwnSpacing>
      <Formik onSubmit={onSubmit} initialValues={{}}>
        {({ isSubmitting }) => (
          <Form {...bem('form')} data-netlify="true">
            <small {...bem('note')}>
              <Trans i18nKey="legal_note_netlify">
                We use Netlify as our hosting provider. By clicking below
                to submit, you acknowledge that your information will be
                transferred to Netlify for processing.
                <a
                  href="https://www.netlify.com/privacy/"
                  target="_blank"
                  rel="noopener"
                  {...bem('note__link')}
                >
                  Learn more about Netlify privacy policy here
                </a>
              </Trans>
            </small>
            <Button
              type="submit"
              variant="primary"
              title={t('Click here to place your order')}
              disabled={isSubmitting}
            >
              {t('Submit order')}
            </Button>
          </Form>
        )}
      </Formik>
    </Content>
  );
};

Order.propTypes = {
  products: arrayOf(shape({
    uid: string.isRequired,
    title: string.isRequired,
    slug: string.isRequired,
    departments: arrayOf(shape({
      slug: string.isRequired,
    })).isRequired,
    variants: arrayOf(shape({
      uid: string.isRequired,
      label: string.isRequired,
      slug: string.isRequired,
      price: number.isRequired,
      sample: bool.isRequired,
    })).isRequired,
  })).isRequired,
  codes: arrayOf(shape({
    uid: string.isRequired,
    code: string.isRequired,
    value: number.isRequired,
  })).isRequired,
};
