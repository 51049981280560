import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Sitemeta } from '../../components/core/sitemeta';
import { Cart } from '../../components/shop/cart';
import { Coupon } from '../../components/shop/coupon';
import { Comment } from '../../components/shop/comment';
import { Order } from '../../components/shop/order';
import { useShop } from '../../hooks/useShop';
import { entriesType, responseType } from '../../types';

export const query = graphql`
  query ShopCartPage {
    response: craftgql {
      ...ShopHookFragment
    }
  }
`;

const ShopCartPage = ({ data }) => {
  const { t } = useTranslation();
  const { products, codes } = data.response;
  const { contactperson, comment } = useShop(products, codes);

  return (
    <>
      <Sitemeta title={t('Your cart')} />
      <Cart products={products} codes={codes} />
      <Comment comment={comment} contactperson={contactperson} />
      <Coupon products={products} codes={codes} />
      <Order products={products} codes={codes} />
    </>
  );
};

ShopCartPage.propTypes = {
  ...responseType({
    products: entriesType({}),
    codes: entriesType({}),
  }),
};

export default ShopCartPage;
