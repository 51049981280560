import { Formik, Form } from 'formik';
import {
  arrayOf, bool, number, string, shape,
} from 'prop-types';
import React, { useCallback } from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';

import { Content } from '../../common/content';
import { Input } from '../../common/form';
import { useCart } from '../../../hooks/useCart';
import { useNotification } from '../../../hooks/useNotification';
import { useShop } from '../../../hooks/useShop';
import './coupon.scss';

const bem = new BEMHelper({
  name: 'content-shop-coupon',
});

export const Coupon = ({ products, codes }) => {
  const { t } = useTranslation();
  const { isEmpty } = useCart();
  const { addNotification } = useNotification();
  const { addCoupon, hasDiscount, isDiscountEnabled } = useShop(products, codes);
  const onSubmit = useCallback(({ code: key }) => {
    const coupon = (codes || []).find(({ code }) => code === key);
    if (!coupon) {
      addNotification('error', t('This is not a valid coupon code.'));
      return;
    }

    try {
      addCoupon(coupon.uid);
      addNotification('success', t('Coupon code successfully applied.'));
    } catch (error) {
      addNotification('error', error.message);
    }
  });

  if (isEmpty || hasDiscount || !isDiscountEnabled) {
    return null;
  }

  return (
    <Content {...bem()} useOwnSpacing>
      <Formik initialValues={{ code: '' }} onSubmit={onSubmit}>
        {({ values, handleBlur }) => (
          <Form {...bem('form')}>
            <h2 {...bem('headline')}>{t('Coupon code')}</h2>

            <Input
              id="code"
              name="code"
              label={t('Enter a coupon code')}
              value={values.code}
              type="text"
              maxLength="20"
              readonly={hasDiscount}
              onBlur={handleBlur}
            />

            <button
              {...bem('button')}
              type="submit"
              title={t('Apply coupon code to cart')}
              disabled={hasDiscount}
            >
              {t('Activate code')}
            </button>
          </Form>
        )}
      </Formik>
    </Content>
  );
};

Coupon.propTypes = {
  products: arrayOf(shape({
    uid: string.isRequired,
    title: string.isRequired,
    slug: string.isRequired,
    departments: arrayOf(shape({
      slug: string.isRequired,
    })).isRequired,
    variants: arrayOf(shape({
      uid: string.isRequired,
      label: string.isRequired,
      slug: string.isRequired,
      price: number.isRequired,
      sample: bool.isRequired,
    })).isRequired,
  })).isRequired,
  codes: arrayOf(shape({
    uid: string.isRequired,
    code: string.isRequired,
    value: number.isRequired,
  })).isRequired,
};
