import { Formik, Form } from 'formik';
import {
  arrayOf, bool, number, string, shape,
} from 'prop-types';
import React, { useCallback } from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';

import { Content } from '../../common/content';
import { Input } from '../../common/form';
import { useShop } from '../../../hooks/useShop';
import './comment.scss';
import {useCart} from "../../../hooks/useCart";

const bem = new BEMHelper({
  name: 'content-shop-comment',
});

export const Comment = ({ comment, contactperson }) => {
  const { t } = useTranslation();
  const { setContactperson, setComment } = useShop();
  const { isEmpty } = useCart();
  const handleBlur = useCallback((e, key) => {
    if ("contactperson" === key) {
      setContactperson(e.currentTarget.value);
    }
    if ("comment" === key) {
      setComment(e.currentTarget.value);
    }
  });

  const onSubmit = useCallback((values, actions) => {
    //console.log("onSubmit", values, actions);
  });

  if (isEmpty) {
    return(
      <>
      </>
    );
  }

  return (
    <Content {...bem()} useOwnSpacing>
      <Formik initialValues={{ comment: comment, contactperson: contactperson }} onSubmit={onSubmit}>
        {({ values }) => (
          <Form {...bem('form')}>
            <h2 {...bem('headline')}>{t('Comment')}</h2>
            <Input
              id="comment"
              name="comment"
              label={t('Enter a comment')}
              value={values.comment}
              type="text"
              as="textarea"
              maxLength="500"
              onBlur={ e => { handleBlur(e, 'comment') } }
            />

            <h2 {...bem('headline')}>{t('Contact person')}</h2>
            <Input
              id="contactperson"
              name="contactperson"
              label={t('Enter a contact person')}
              value={values.contactperson}
              type="text"
              as="input"
              maxLength="100"
              onBlur={ e => { handleBlur(e, 'contactperson') } }
            />

          </Form>
        )}
      </Formik>
    </Content>
  );
};

Comment.propTypes = {
  contact: string,
  comment: string
};
